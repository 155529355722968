@tailwind base;
@tailwind components;
@tailwind utilities;




body, html {
background: #E1FAE5;
font-family: 'Poppins', sans-serif;
}

@font-face {
  font-family: Poppins;
  src: url( './assets/fonts/Poppins-Regular.ttf' ) format(' truetype');
}

p {
  color: #6F736D;
  font-size:small;
}

h2 {
  color: #6F736D;
}






h1 {
  font-size: 1.8rem;
  font-weight: 600;
  background: linear-gradient(278.91deg, #E18762 37.1%, #FFA841 125.24%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

h6 {
    font-size: 0.8rem;
      font-weight: 600;
      background: linear-gradient(278.91deg, #E18762 37.1%, #FFA841 125.24%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      display: inline-block;
}

h5 {
  color: #E18762;
  font-size:medium;
}


button {
  background: linear-gradient(278.91deg, #E18762 37.1%, #FFA841 125.24%);
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  padding: 10px;
}